<template>
    <div class="absolute h-full w-full top-0 z-50 px-3 flex flex-row justify-center items-center">
        <div class="absolute h-full w-full top-0 bg-black opacity-50 z-30"></div>
        <div class="relative bg-white rounded-md shadow-lg p-3 w-full z-40">
            <template v-if="isProcessingUssdCode">
                <div class="flex flex-row justify-center items-center">
                    <svg class="loader h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><g fill="none"><circle cx="22" cy="22" r="20"/><circle cx="22" cy="22" r="20"/></g></svg>
                    <span class="text ml-3 text-gray-600">USSD code running...</span>
                </div>
            </template>
            <template v-if="hasUssdMenu">
                <div class="flex flex-col">
                    <div class="text-md text-gray-800" v-html="formatMenu(ussdMenuText)"></div>
                    <div
                        v-if="menuAcceptsInput"
                        class="mt-4">
                        <input
                            type="text"
                            v-model.trim="ussdInputText"
                            @keyup.enter="sendUssdInput"
                            class="outline-none text-gray-800 border-b-2 border-gray-500 focus:border-orange-500 py-1 w-full"
                        />
                    </div>
                    <div class="flex flex-row mt-4 justify-end">
                        <template v-if="menuAcceptsInput">
                            <button
                                @click="closeUssd"
                                class="text-orange-500 hover:text-orange-600 font-semibold uppercase">Cancel</button>
                            <button
                                @click="sendUssdInput"
                                class="text-orange-500 hover:text-orange-600 font-semibold uppercase ml-5">Send</button>
                        </template>
                        <template v-else>
                            <button
                                @click="closeUssd"
                                class="text-orange-500 hover:text-orange-600 font-semibold uppercase">Ok</button>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapGetters,
} from 'vuex';

export default {
    name: 'UssdScreen',

    data() {
        return {
            ussdInputText: '',
        };
    },

    computed: {
        ...mapState('simulator/ussd', {
            ussdMenuText: 'text',
        }),

        ...mapGetters('simulator/ussd', {
            isProcessingUssdCode: 'processing',
            hasUssdMenu: 'hasMenu',
            menuAcceptsInput: 'acceptsInput',
        }),
    },

    methods: {
        sendUssdInput() {
            if (this.ussdInputText.length !== 0) {
                this.sendUssd({ inputText: this.ussdInputText });
                this.ussdInputText = '';
            }
        },

        sendUssd(t) {
            this.$store.dispatch('simulator/ussd/sendUssd', t);
        },

        closeUssd() {
            this.$store.commit('simulator/ussd/closeUssd');
        },

        formatMenu(menuText) {
            const formattedText = menuText.replace(/\n/g, '<br>');
            return formattedText;
        },
    },
};
</script>

<style lang="postcss" scoped>
@keyframes size {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
}

@keyframes opacity {
    5% { opacity: 1; }
    100% { opacity: 0; }
}

.loader g circle {
    stroke: theme('colors.gray.600');
    stroke-width: 3;
    opacity: 0;
    animation:
        size cubic-bezier(.165,.84,.44,1) 1.8s infinite,
        opacity cubic-bezier(.3,.61,.355,1) 1.8s infinite;
    transform-origin: 50% 50%;

    &:last-child {
        animation-delay: .9s;
    }
}
</style>
